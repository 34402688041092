<template>
  <div>
    <portal to="subheader_actions">
      <b-button @click="modalShow = !modalShow" class="mr-2">
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-table
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              ></b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>

    <b-modal v-model="modalShow" title="Agregar especificación técnica">
      <b-form-group label="Color:">
        <b-input-group class="mt-3">
          <b-form-select
            v-model="form.color"
            :options="colors"
            text-field="description"
            value-field="id"
            @input="changeButtonColor()"
          ></b-form-select>
          <b-button v-bind:style="{ 'background-color': this.buttonColor }"
            >---
          </b-button>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Código:">
        <b-form-input v-model="form.code" type="text" required></b-form-input>
      </b-form-group>

      <b-form-group label="Descripción:">
        <b-form-input
          v-model="form.description"
          type="text"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Detalle:">
        <b-form-input v-model="form.detail" type="text" required></b-form-input>
      </b-form-group>

      <b-form-checkbox v-model="form.is_active" value="1" unchecked-value="0">
        ¿Está activo?
      </b-form-checkbox>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="saveItem()">
          OK
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      buttonColor: null,
      colors: [],
      form: { color: null, is_active: 1 },
      modalShow: false,
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "code",
          sortable: true,
          label: "Código"
        },
        {
          key: "description",
          sortable: true,
          label: "Descripción"
        },
        {
          key: "detail",
          sortable: true,
          label: "Detalle"
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    changeButtonColor() {
      let selectedColor = this.form.color;
      let colorCode = null;
      this.colors.map(function (i) {
        if (selectedColor === i.id) {
          colorCode = i.color_code;
        }
      });
      this.buttonColor = colorCode;
    },
    chargeSelects() {
      ApiService.get("api/color", "").then((response) => {
        this.colors = response.data;
      });
    },
    saveItem() {
      ApiService.post("api/item-spec-tech/", this.form)
        .then(() => {
          this.modalShow = false;
          this.$bvToast.toast("Se ha creado el item correctamente", {
            title: `Éxito`,
            variant: "success",
            solid: true
          });
          this.listItems();
        })
        .catch(() => {
          this.$bvToast.toast("Ha ocurrido un error", {
            title: `Error`,
            variant: "danger",
            solid: true
          });
        });
    },
    listItems() {
      ApiService.get("api/item-spec-tech", "").then((response) => {
        this.items = response.data;
      });
    }
  },
  created() {
    this.listItems();
    this.chargeSelects();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Espec. Técnicas" }
    ]);
  }
};
</script>
